<template lang="pug">
	#kt_subheader.subheader.py-2.py-lg-4(v-bind:class="subheaderClasses")
		.d-flex.align-items-center.justify-content-between.flex-wrap.flex-sm-nowrap(v-bind:class="{'container-fluid': widthFluid, container: !widthFluid }")
			router-link(:to="backButton.route", v-if="backButton")
				button.btn.font-weight-bold.btn-sm.btn-light Back
			.d-flex.buttons-wrapper
				span(v-for="b in buttons")
					button.btn.font-weight-bold.btn-sm(@click="b.action()", :class="b.class") {{ b.label }}
</template>

<style lang="scss">
.custom-v-dropdown {
	&.dropdown-toggle {
		padding: 0;

		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	&.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;

		.b-dropdown-text {
			padding: 0;
		}
	}
}
.buttons-wrapper {
	width: 100%;
	justify-content: flex-end;
	&> span {
		padding-left: 10px;
		&:first-child {
			padding-left: 0;
		}
	}
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
	name: "KTSubheader",
	props: {
		breadcrumbs: Array,
		title: String
	},
	computed: {
		...mapGetters(['layoutConfig', 'getButtons', 'getBackButton']),

		/**
		 * Check if subheader width is fluid
		 */
		widthFluid(){
			return this.layoutConfig('subheader.width') === 'fluid';
		},

		buttons(){
			return this.getButtons();
		},
		backButton(){
			return this.getBackButton();
		},

		subheaderClasses(){
			const classes = [];
			const style = this.layoutConfig("subheader.style");
			if (style) {
				classes.push(style);

				if (style === "solid") {
					classes.push("bg-white");
				}

				if (this.layoutConfig("subheader.fixed")) {
					classes.push("border-top");
				}
			}
			return classes.join(" ");
		}
	}
};
</script>
