<template lang="pug">
	ul.menu-nav

		template(v-for="(group, groupIndex) in menuItems")
			li.menu-section(v-if="group.label" :key="groupIndex")
				h4.menu-text {{group.label}}
				i.menu-icon.flaticon-more-v2
			template(v-for="(item, itemIndex) in group.items")
				router-link(v-if="!item.children", :key="groupIndex+'_'+itemIndex", :to="'/app'+item.path", v-slot="{ href, navigate, isActive, isExactActive }")
					li(aria-haspopup="true", data-menu-toggle="hover" class="menu-item",
						:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]")
						a.menu-link(:href="href", @click="navigate")
							i.menu-icon(:class="item.icon")
							span.menu-text {{ item.label }}

				li.menu-item.menu-item-submenu(v-if="item.children && item.children.length", :key="groupIndex+'_'+itemIndex",
					aria-haspopup="true", data-menu-toggle="hover", :class="{'menu-item-open': hasActiveChildren('/app'+group.path+item.path)}")
					a.menu-link.menu-toggle(href="#")
						i.menu-icon(:class="item.icon")
						span.menu-text {{item.label }}
						i.menu-arrow
					div.menu-submenu.menu-submenu-classic.menu-submenu-right
						ul.menu-subnav
							router-link(v-for="(subItem, subItemIndex) in item.children", :key="subItemIndex",
								:to="'/app'+item.path+subItem.path", v-slot="{ href, navigate, isActive, isExactActive }")
								li.menu-item(aria-haspopup="true", data-menu-toggle="hover",
									:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']")
									a.menu-link(:href="href", @click="navigate")
										//i.menu-bullet.menu-bullet-dot
											span
										span.menu-text {{ subItem.label }}
</template>

<script>
export default {
	name: "KTMenu",
	data(){
		return {
			menuItems: [
				{
					label: null,
					items: [
						{
							label: 'Dashboard',
							icon: 'flaticon2-architecture-and-city',
							path: '/'
						},
						{
							label: 'Demand',
							icon: 'flaticon2-expand',
							path: '/demand',
							children: [
								{
									label: 'Advertisers',
									path: '/advertisers'
								},
								{
									label: 'Offers',
									path: '/offers'
								},
								{
									label: 'Ext. Offers',
									path: '/external-offers'
								},
								{
									label: 'Assets',
									path: '/assets'
								},
								{
									label: 'Data Control',
									path: '/data-control'
								},
								{
									label: 'Source Blacklist',
									path: '/data-control/source-blacklist'
								},
							]
						},
						{
							label: 'Supply',
							icon: 'flaticon2-expand',
							path: '/supply',
							children: [
								{
									label: 'Publishers',
									path: '/publishers'
								},
								{
									label: 'Placements',
									path: '/placements'
								},
								{
									label: 'Creatives',
									path: '/creatives'
								},
								{
									label: 'Tags',
									path: '/tags'
								},
							]
						},
						{
							label: 'Reports',
							icon: 'flaticon2-expand',
							path: '/reports',
							children: [
								{
									label: 'Reports',
									path: ''
								},
								{
									label: 'Events',
									path: '/events'
								},
								{
									label: 'Rejection Events',
									path: '/rejection-events'
								},
								{
									label: 'Clicks',
									path: '/clicks'
								},
								{
									label: 'Postback Log',
									path: '/postback-log'
								},
								{
									label: 'Publisher Postbacks',
									path: '/publisher-postbacks'
								},
							]
						},
						{
							label: 'Settings',
							icon: 'flaticon2-settings',
							path: '/settings',
							children: [
								// {
								// 	label: 'Categories',
								// 	path: '/categories'
								// },
								{
									label: 'Users',
									path: '/users'
								},
							]
						},
					]
				},
			]
		};
	},
	methods: {
		hasActiveChildren(match){
			return this.$route["path"].indexOf(match) !== -1;
		}
	}
};
</script>
