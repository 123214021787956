<template lang="pug">
	#kt_header.header(ref="kt_header", v-bind:class="headerClasses")
		.container-fluid.d-flex.align-items-center.justify-content-between
			.d-flex.align-items-center.flex-wrap.mr-1
				h5.text-dark.font-weight-bold.my-2.mr-5 {{ title }}
				ul.breadcrumb.breadcrumb-transparent.breadcrumb-dot.font-weight-bold.p-0.my-2
					li.breadcrumb-item
						router-link.subheader-breadcrumbs-home(:to="'/'")
							i.flaticon2-shelter.text-muted.icon-1x

					template(v-for="(breadcrumb, i) in breadcrumbs")
						li.breadcrumb-item(:key="`${i}-${breadcrumb.id}`")
							router-link.text-muted(v-if="breadcrumb.route", :key="i", :to="breadcrumb.route") {{ breadcrumb.title }}
							span.text-muted(:key="i", v-if="!breadcrumb.route") {{ breadcrumb.title }}

			KTTopbar
</template>
<script>
import {mapGetters} from "vuex";
import KTTopbar from "@/views/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
	name: "KTHeader",
	props: {
		breadcrumbs: Array,
		title: String
	},
	components: {
		KTTopbar,
	},
	mounted(){
		// Init Desktop & Mobile Headers
		KTLayoutHeader.init("kt_header", "kt_header_mobile");

		// Init Header Menu
		KTLayoutHeaderMenu.init(
			this.$refs["kt_header_menu"],
			this.$refs["kt_header_menu_wrapper"]
		);
	},
	computed: {
		...mapGetters(["layoutConfig", "getClasses"]),

		/**
		 * Check if the header menu is enabled
		 * @returns {boolean}
		 */
		headerMenuEnabled(){
			return !!this.layoutConfig("header.menu.self.display");
		},

		/**
		 * Get extra classes for header based on the options
		 * @returns {null|*}
		 */
		headerClasses(){
			const classes = this.getClasses("header");
			if (typeof classes !== "undefined") {
				return classes.join(" ");
			}
			return null;
		},

		/**
		 * Get extra classes for header menu based on the options
		 * @returns {null|*}
		 */
		headerMenuClasses(){
			const classes = this.getClasses("header_menu");
			if (typeof classes !== "undefined") {
				return classes.join(" ");
			}
			return null;
		}
	}
};
</script>
